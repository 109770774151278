import React from "react";

class MaintenanceServiceReq extends React.Component {
    constructor() {
        super();
        this.state = {}
    }

    render() {
        return (
            <div class="p-4">
                <h1>Maintenance Service Request</h1>
            </div>
        );
    }
}

export default MaintenanceServiceReq;
