import React from 'react';
import { getRandomInt } from '../../utils';
import { Table, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import './List.css';

class PurchaseOrders extends React.Component {
    constructor() {
        super();
        let now = new Date();
        this.state = {
            searchCriteria: '',
            pending: [
                {
                    id: 1,
                    name: 'Filexoid',
                    slug: 'filexoid',
                    num: getRandomInt(1000000),
                    file: '/data/POs/PO-001.pdf',
                    lastTs: now.setDate(now.getDate() - 7)
                },
                {
                    id: 2,
                    name: 'Polist LLC',
                    slug: 'polist',
                    num: getRandomInt(9999),
                    file: '/data/POs/PO-002.pdf',
                    lastTs: now.setDate(now.getDate() - 3)
                },
                {
                    id: 3,
                    name: 'Southset Global',
                    slug: 'southset',
                    num: getRandomInt(9999),
                    file: '/data/POs/PO-003.pdf',
                    lastTs: now.setDate(now.getDate() - 1)
                },
                {
                    id: 4,
                    name: 'Monsoni',
                    slug: 'monsoni',
                    num: getRandomInt(9999),
                    file: '/data/POs/PO-004.pdf',
                    lastTs: now.setDate(now.getDate() - 13)
                },
                {
                    id: 5,
                    name: 'Gleason Group',
                    slug: 'gleason',
                    num: getRandomInt(9999),
                    file: '/data/POs/PO-005.pdf',
                    lastTs: now.setDate(now.getDate() - 9)
                }    
            ]
        }
    }

    onSearch = () => {
        return this.state.pending.filter(po => po.name.toLowerCase().indexOf(this.state.searchCriteria.toLowerCase()) >= 0);
    }

    onRemove = (id) => {
        console.log(`removing ${id}`)
        this.setState({
            pending: this.state.pending.filter(po => po.id !== id)
        });
    }

    onNext = (id) => {
    }

    render() {
        return (
            <div class="p-4">
                <h1>PO Dropbox</h1>
                <div class="path-container">
                    <i class="fa fa-folder pre-icon"/><code>/public/inbound/pos/</code>
                </div>
                <div  class="w-25">
                    <Form.Control id="search-criteria" placeholder="Search Provider" onChange={(e) => this.setState( { searchCriteria: e.target.value })} />
                </div>
                <Table striped>
                <thead>
                    <tr>
                        <th>Provider</th>
                        <th>Status</th>
                        <th>Last Activity</th>
                        <th>PO</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {this.onSearch().map((po, i) => {
                        return (
                            <tr key={i}>
                                <td>{po.name}</td>
                                <td>NEW</td>
                                <td>{format(po.lastTs, 'MMM dd, yyyy')}</td>
                                <td><Link id={`download-po-${i}`} to={po.file} target="_blank">{`${po.slug}-PO-${po.num}.pdf`}</Link></td>
                                <td>
                                    <button id={`remove-po-${i}`} onClick={() => this.onRemove(po.id)}><i className="fa fa-trash"></i></button>&nbsp;
                                    <button id={`next-po-${i}`} onClick={() => this.onNext(po.id)}><i className="fa fa-arrow-right"></i></button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                </Table>
                <nav aria-label="Pagination">
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">Next</a></li>
                </ul>
                </nav>
            </div>
        );
    }
}

export default PurchaseOrders;
