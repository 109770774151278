import { Outlet } from "react-router-dom";
import { Container, Nav, Navbar, Row, Col } from "react-bootstrap";
import Menu from './components/menu/Menu'
import './Layout.css';

const Layout = () => {
  return (
    <>
      <Container>
        <Menu displayMenuItems={ true }/>
        <Row>
            <Col xs={1} >
              <br/>
            </Col>
            <Col xs={10}>
              <Outlet />
            </Col>
        </Row>
      </Container>
    </>
  )
};

export default Layout;
