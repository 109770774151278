import React from "react";
import {
  Table,
  Form,
  Container,
  Row,
  Col,
  Button,
  Modal,
  Badge,
} from "react-bootstrap";
import { format } from "date-fns";
import "./List.css";

class Orders extends React.Component {
  constructor() {
    super();
    let now = new Date();
    this.const = {
      status: {
        COMPLETED: "Completed",
        IN_PROGRESS: "In Progress",
        CANCELLED: "Cancelled",
      },
    };
    this.state = {
      show: false,
      searchCriteria: "",
      orders: [
        {
          id: 1,
          number: "5987647",
          name: "Frances Key",
          amount: "19.99",
          created: new Date().setDate(now.getDate() - 3),
          status: this.const.status.COMPLETED,
          requestor: "Stellar Dynamics"
        },
        {
          id: 2,
          number: "5987646",
          name: "Bentley Smith",
          amount: "25.99",
          created: new Date().setDate(now.getDate() - 3),
          status: this.const.status.COMPLETED,
          requestor: "Apex Insights"
        },
        {
          id: 3,
          number: "5987645",
          name: "Gary Black",
          amount: "19.99",
          created: new Date().setDate(now.getDate() - 3),
          status: this.const.status.COMPLETED,
          requestor: "Apex Insights"
        },
        {
          id: 4,
          number: "5987644",
          name: "Gemma Ngo",
          amount: "32.50",
          created: new Date().setDate(now.getDate() - 3),
          status: this.const.status.COMPLETED,
          requestor: "Fusion Insights"
        },
        {
          id: 5,
          number: "5987643",
          name: "Greg Hammon",
          amount: "10.75",
          created: new Date().setDate(now.getDate() - 3),
          status: this.const.status.COMPLETED,
          requestor: "Stellar Dynamics"
        },
        {
          id: 6,
          number: "5987642",
          name: "Mary Crespo",
          amount: "19.99",
          created: new Date().setDate(now.getDate() - 3),
          status: this.const.status.COMPLETED,
          requestor: "Apex Analytics"
        },
        {
          id: 7,
          number: "5987641",
          name: "Kai Nakamura",
          amount: "9.99",
          created: new Date().setDate(now.getDate() - 4),
          status: this.const.status.COMPLETED,
          requestor: "Fusion Insights"
        },
        {
          id: 8,
          number: "5987640",
          name: "Carmen H. Green",
          amount: "32.50",
          created: new Date().setDate(now.getDate() - 4),
          status: this.const.status.COMPLETED,
          requestor: "Fusion Insights"
        },
        {
          id: 9,
          number: "5987639",
          name: "Henrik Andersen",
          amount: "35.50",
          created: new Date().setDate(now.getDate() - 4),
          status: this.const.status.CANCELLED,
          requestor: "Stellar Dynamics"
        },
        {
          id: 10,
          number: "5987638",
          name: "Dai Li",
          amount: "5.45",
          created: new Date().setDate(now.getDate() - 5),
          status: this.const.status.COMPLETED,
          requestor: "Apex Analytics"
        },
      ],
      newOrders: [
        {
          id: 13,
          number: "5987650",
          name: "Juan Jimenez",
          amount: "19.99",
          created: now,
          status: "In Progress",
          requestor: "Stellar Dynamics"
        },
        {
          id: 12,
          number: "5987649",
          name: "Tien Bui",
          amount: "42.75",
          created: now,
          status: "In Progress",
          requestor: "Stellar Dynamics"
        },
        {
          id: 11,
          number: "5987648",
          name: "Kyle Miller",
          amount: "53.91",
          created: now,
          status: "In Progress",
          requestor: "Stellar Dynamics"
        },
      ],
    };
  }

  onSearch = () => {
    const criteria = this.state.searchCriteria.toUpperCase();
    return this.sortByNumber(
      this.state.orders.filter(
        (o) => o.name.toUpperCase().indexOf(criteria) >= 0
      )
    ).slice(0, 10);
  };

  onRemove = (id) => {
    this.setState({
      orders: this.state.orders.filter((o) => o.id !== id),
    });
  };

  onNext = (id) => {};

  showAddOrderModal = (shouldShow) => {
    this.setState({
      show: shouldShow,
    });
  };

  addNewOrder = () => {
    this.state.orders.push(this.state.newOrders.pop());
  };

  sortByNumber = (orders) => {
    return orders.sort((a, b) => {
      const numberA = a.number.toUpperCase();
      const numberB = b.number.toUpperCase();
      if (numberA < numberB) {
        return 1;
      }
      if (numberA > numberB) {
        return -1;
      }
      return 0;
    });
  };

  render() {
    return (
      <div class="p-4">
        <h1>Orders</h1>
        <Container fluid>
          <Row>
            <Col xs={3}>
              <Form.Control
                id="search-criteria"
                placeholder="Search"
                onChange={(e) =>
                  this.setState({ searchCriteria: e.target.value })
                }
              />
            </Col>
            <Col xs={8}>
              <Button
                id="add-order-btn"
                variant="primary"
                onClick={() => this.showAddOrderModal(true)}
              > <span>Add Order</span>
              </Button>
            </Col>
          </Row>
        </Container>

        <div class="w-25"></div>
        <Table striped>
          <thead>
            <tr>
              <th>Purchase Order #</th>
              <th>Name</th>
              <th>Requestor</th>
              <th className="currency">Amount</th>
              <th>Created Date</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.onSearch().map((o, i) => {
              return (
                <tr key={i}>
                  <td>{o.number}</td>
                  <td>{o.name}</td>
                  <td>{o.requestor}</td>
                  <td className="currency">$ {o.amount}</td>
                  <td>{format(o.created, "MMM dd, yyyy")}</td>
                  <td>
                    <Badge
                      pill
                      bg={(() => {
                        switch (o.status) {
                          case this.const.status.COMPLETED:
                            return "success";
                          case this.const.status.CANCELLED:
                            return "danger";
                          default:
                            return "primary";
                        }
                      })()}
                    >
                      {o.status}
                    </Badge>
                  </td>
                  <td>
                    <button
                      id={`remove-${i}`}
                      onClick={() => this.onRemove(o.id)}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                    &nbsp;
                    <button id={`next-${i}`} onClick={() => this.onNext(o.id)}>
                      <i className="fa fa-file"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <nav aria-label="Pagination">
          <ul class="pagination">
            <li class="page-item">
              <a class="page-link" href="#">
                Previous
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                1
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                2
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                3
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                ...
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                23
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                Next
              </a>
            </li>
          </ul>
        </nav>

        <Modal
          show={this.state.show}
          onHide={() => this.showAddOrderModal(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              New Purchase Order from Excel Template
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formFileSm" className="mb-3">
              <Form.Label>Load Excel file containing order details.</Form.Label>
              <Form.Control type="file" size="sm" />
            </Form.Group>
            <Form.Group controlId="formOrderType" className="mb-3">
              <Form.Label>Requestor</Form.Label>
              <Form.Control as="select" onChange={this.handleTypeChange}>
                <option value="stellarDynamics">Stellar Dynamics</option>
                <option value="fusionInsights">Fusion Insights</option>
                <option value="apexAnalytics">Apex Analytics</option>
              </Form.Control>
            </Form.Group>
            <Button
              id="create-btn"
              variant="primary"
              onClick={() => {
                this.showAddOrderModal(false);
                this.addNewOrder();
              }}
            >
              Create New Order
            </Button>{" "}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Orders;
