export var getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
}

export var onDownload = (path, name) => {
    fetch(path).then(response => {
        response.blob().then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = name;
            alink.target = '_blank';
            alink.click();
        })
    })
}
