import React, { useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/login/Login.js';
import Home from './components/home/Home.js';
import PurchaseOrders from './components/po/List.js';
import Accounts from './components/acc/List.js';
import MaintenanceServiceReq from './components/maint/MaintenanceServiceReq';
import Layout from './Layout';
import NoPage from './NoPage';

function App() {
  const [token, setToken] = useState();

  if(!token) {
    return <Login setToken={setToken} />
  }

  return (
    <div className="wrapper">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="pos" element={<PurchaseOrders />} />
            <Route path="acc" element={<Accounts />} />
            <Route path="maint" element={<MaintenanceServiceReq />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
