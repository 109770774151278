import { Nav, Navbar } from "react-bootstrap";
import PropTypes from 'prop-types';
import './Menu.css';

const Menu = ({ displayMenuItems = false }) => {
  return (
    <Navbar bg="light" variant="dark" expand="lg" sticky="top">
      <Navbar.Brand href="#home">
      <div style={{ marginLeft: 40 }}>
        <img src="./img/generic-logo.png" height="40"/>
        <span className='orgName'>Legacy Checks</span>
        {displayMenuItems ? <span className='orgName' style={{ fontSize: 20 }}>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;</span> : ''}     
      </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      {displayMenuItems ?
      <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
          <Nav.Link href="/">Personal Products</Nav.Link>
          <Nav.Link href="/">Business Products</Nav.Link>
          <Nav.Link href="/">Customer Service</Nav.Link>
          <Nav.Link href="/">About</Nav.Link>
          </Nav>
      </Navbar.Collapse> : ''}
  </Navbar>
)
};

export default Menu;

Menu.propTypes = {
  displayMenuItems: PropTypes.bool
}
