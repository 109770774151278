import React, { useState } from 'react';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Menu from '../menu/Menu'

export default function Login({ setToken }) {
    const VALID_USER = '.sa'

    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    const handleSubmit = e => {
        e.preventDefault();
        if (username.endsWith(VALID_USER)) {
            setToken('always-auth');
        } else {
            setErrorMsg("Incorrect username or password.");
        }
      }

    return (
        <>
            <Container>
                <Menu/>
                <div class="mx-auto w-25 p-3">
                    <h1>Sign in</h1>
                    <Form onSubmit={handleSubmit}>
                        {
                            errorMsg &&
                            <Form.Group className="mb-3" controlId="msg">
                                <Alert variant='danger'>{errorMsg}</Alert>
                            </Form.Group>
                        }
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Username</Form.Label>
                            <Form.Control id='username' onChange={e => setUserName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="Password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control id='password' type="password" onChange={e => setPassword(e.target.value)} />
                        </Form.Group>
                        <Button id='login-btn' variant="primary" type="submit">Sign in</Button>
                    </Form>
                </div>
            </Container>
        </>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}
